//@ts-nocheck
import { useHomeContext } from "../../pages/Home/context";
import { GLOBAL } from "../../utils/GLOBAL";
import { CepMask } from "../../utils/constants";
import SvgUse from "../svg/SvgUse";
import InputMask from "react-input-mask";
import TabOne from "./components/TabCollapse/TabOne";
import TabTwo from "./components/TabCollapse/TabTwo";
import TabThree from "./components/TabCollapse/TabThree";
import { useRef } from "react";
import { Dialog } from "primereact/dialog";
import Video from "/reconhecimento_facial.mp4";
const StepOne = (): JSX.Element => {
  const {
    changeTab,
    dataByCpf,
    dataBank,
    dataTaxa,
    backStageZero,
    clicked,
    showAdvertisement,
    setShowAdvertisement,
    onClickSecureOperation,
    nextStep,
    openCameraNormal,
    numTentativas,
    maxTentativas,
    activeTab,
    errorMessage,
  } = useHomeContext();

  return (
    <>
      <Dialog
        draggable={false}
        header="Falha na captura da imagem"
        closable={false}
        visible={showAdvertisement}
        contentClassName="!px-24 !pt-16 !pb-24"
        className="w-[400px] !max-h-[80vh] dialog-adversitement"
        onHide={() => setShowAdvertisement(false)}
      >
        <div className="">
          <p className="m-0">
            Você tem <span className="font-bold">{maxTentativas - numTentativas} tentativas</span>  para tirar uma foto que atenda aos requisitos abaixo:
          </p>
        </div>

        <video className="rounded-md mt-16" autoPlay loop playsInline>
          <source src="/reconhecimento_facial.mp4" type="video/mp4" />
          Seu navegador não suporta a reprodução de vídeos.
        </video>

        <div className="flex justify-center  flex-col">

          <button
            className={`h-40 btn flex  min-w-[200px] mt-16 mx-auto !w-full`}
            onClick={() => {
              setShowAdvertisement(false);
              openCameraNormal();
            }}
          >
            <p className="text-white text-lg w-full">Tentar Novamente</p>
          </button>
        </div>
      </Dialog>
      <div className="tabs overflow-hidden" js-tab="true">
        <ul className="tabs-wrapper flex">
          <li
            data-tab="tab-2"
            className={`${activeTab === 2 ? "active" : "!bg-[#ffffff]"
              } tab-item flex-1 whitespace-nowrap flex justify-center text-center}`}
          >
            Dados Pessoais
          </li>
          <li
            className={`${activeTab === 3 ? "active" : "bg-white"
              } tab-item flex-1 whitespace-nowrap flex justify-center text-center}`}
            data-tab="tab-3"
          >
            Confirmar
          </li>
          <li
            className={`${activeTab === 4 ? "active" : "!bg-[#ffffff]"
              } tab-item flex-1 whitespace-nowrap flex justify-center text-center}`}
            data-tab="tab-4"
          >
            Selfie
          </li>
        </ul>
        <div className="tabs-content  h-full !bg-[#ffffff] !border-t !border-solid !border-t-neutral-100/10">
          {activeTab === 2 && <TabOne />}
          {activeTab === 3 && <TabTwo />}
          {activeTab === 4 && <TabThree />}
        </div>
      </div>
    </>
  );
};

export default StepOne;
