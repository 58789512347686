import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { countries } from "./countries";
import { HttpClientToken } from "./HttpClientToken";

export const GLOBAL = {
  showToastify: (text: string, duration = 3500, classe?: string, style?: {}) => {
    Toastify({
      text: text,
      duration: duration,
      position: "center",
      className: classe ? classe : "",
      style: style ? style : {},
      
    }).showToast();
  },
  FormatCurrencyBR: (v: string) => {
    if (!v) return;

    const value = v.replace(",", ".").split(".");

    const [int, cents] = value;
    const money = Number(int).toLocaleString("pt-br", {
      minimumFractionDigits: 0,
    });
    return `R$ ${money},${cents}`;
  },
  getFingerprintBrowser: async () => {
    const systemVersion = (window.navigator as any)?.userAgentData?.mobile ? navigator?.userAgent?.split("(")[1].split(";")[1] : navigator?.userAgent?.split("(")[1].split(";")[0];

    const modelMobile = navigator?.userAgent?.split("(")[1]?.split(";")[2]?.split(")")[0];
    const model = (window.navigator as any)?.userAgentData?.mobile ? modelMobile.slice(0, modelMobile.indexOf(")")) : navigator?.userAgent?.split("(")[1].split(";")[0];

    const response = await fetch("https://ipapi.co/json/");
    const dataAddress = await response.json();

    const fingersprint = {
      lat: 0,
      long: 0,
      name: model,
      model,
      calendar: "",
      regionCode: "",
      country: "",
      systemName: (window.navigator as any)?.userAgentData?.platform,
      orientation: "",
      currencyCode: [""],
      languageCode: "",
      systemVersion,
      userinterface: (window.navigator as any)?.userAgentData?.platform,
      localizedModel: model,
      decimalSeparator: "",
      preferredLanguages: "",
      currencySymbol: "",
      city: dataAddress?.city,
      state: dataAddress?.region,
      stateCode: dataAddress?.region_code,
      ipAddress: dataAddress?.ip,
      network: dataAddress?.network,
      networkVersion: dataAddress?.version,
      timezone: dataAddress?.timezone,
      utc_offset: dataAddress?.utc_offset,
      codePhone: dataAddress?.country_calling_code,
      country_population: dataAddress?.country_population,
    };

    const getGeolocation = () => {
      navigator?.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
        fingersprint.lat = latitude;
        fingersprint.long = longitude;
      });
    };

    getGeolocation();

    const countrySelected = countries.find((country) => country.alpha2 == String(navigator?.language).replace(/^[a-z]+-/gi, ""));

    if (countrySelected) {
      fingersprint.country = countrySelected?.name;
      fingersprint.currencyCode = countrySelected?.currencies;
      fingersprint.regionCode = countrySelected?.alpha2;
      fingersprint.languageCode = navigator?.language;
      fingersprint.preferredLanguages = navigator?.languages?.join(",");
      fingersprint.decimalSeparator = new Intl.NumberFormat(countrySelected?.alpha2, {
        style: "currency",
        currency: countrySelected?.currencies[0],
      })
        .format(1000)
        .replace(/[a-z]\W+/gi, "")
        .slice(-3)[0];

      fingersprint.currencySymbol = new Intl.NumberFormat(countrySelected?.alpha2, {
        style: "currency",
        currency: countrySelected?.currencies[0],
      })
        .format(1000)
        .replace(/\d+| |\.|,/gi, "");
    }

    if (window.matchMedia("(orientation: portrait)").matches) {
      fingersprint.orientation = "Portrait";
    } else {
      fingersprint.orientation = "Landscape";
    }

    const calendar = new Date().toLocaleString(navigator.language, { hour: "numeric", minute: "numeric", hour12: true }).replace(/\d+ |[0-9]|:/gi, "");

    fingersprint.calendar = calendar;

    return fingersprint;
  },

  /////////////////////////////////////////

	async getTokens() {
		const urlToken = '/token/'
		try {
			// const { data } = await axios.post(urlToken, payloadToken)
      const results = await HttpClientToken(urlToken, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          username: process.env.REACT_APP_USER,
          password: process.env.REACT_APP_PASSWORD
        }),
      });
			document.cookie = `accessToken=${results.access}; path=/;`;
			document.cookie = `refreshToken=${results.refresh}; path=/;`;			
		} catch (error) {
			console.log(error)
		}
	},

	/////////////////////////////////////////

	async refreshTokenValue() {
		const urlRefreshToken = '/token/refresh/'
		if (!GLOBAL.getCookie('refreshToken')) {
			window.location.href = '/logout/'
			return
		}
		try {
			// const { data } = await axios.post(urlRefreshToken, payloadRefreshToken)
      const data = await HttpClientToken(urlRefreshToken, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          refresh: GLOBAL.getCookie('refreshToken')
        }),
      });
			document.cookie = `accessToken=${data.access}; path=/;`;
			document.cookie = `refreshToken=${data.refresh}; path=/;`;
		} catch (error) {
			console.log(error)
		}
	},

	getCookie(cName: string) {
		const name = cName + "=";
		const cDecoded = decodeURIComponent(document.cookie); //to be careful
		const cArr = cDecoded .split('; ');
		let res;
		cArr.forEach(val => {
				if (val.indexOf(name) === 0) res = val.substring(name.length);
		})
		return res;
	},

	/////////////////////////////////////////

	clearTokenCookies() {
		document.cookie = `accessToken=${GLOBAL.getCookie('accessToken')}; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
		document.cookie = `refreshToken=${GLOBAL.getCookie('refreshToken')}; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
	},

	/////////////////////////////////////////
};
