import { GLOBAL } from "./GLOBAL";
export const HttpClient = async (url: string, { headers = {}, ...options }) => {
    // return await fetch(`${process.env.NODE_ENV == 'development' ? process.env.REACT_APP_URL_DEV : process.env.REACT_APP_URL_PROD}${url}`, {
    return await fetch(`${process.env.REACT_APP_URL}${url}`, {
        headers: {
            // Authorization: `Token ${process.env.NODE_ENV == 'development' ? process.env.REACT_APP_TOKEN_DEV : process.env.REACT_APP_TOKEN_PROD}`,
            // Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
            Authorization: `Bearer ${GLOBAL.getCookie('accessToken')}`,
            ...headers
        },
        mode: 'cors',
        ...options
    }).then(async (response) => {
        const res = await response.json();
        if (res?.code === 'token_not_valid') {
            throw new Error(res.code);
        }
        return res;
    }).catch(async (err) => {
        if (err?.message === 'token_not_valid') {
            await GLOBAL.refreshTokenValue();
            throw new Error('refreshToken');
        }
        console.log(err);
        throw new Error('Erro na requisição: ' + err.message);
    })

}