import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/Home";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

function App() {
  return (
      /*     <BrowserRouter basename="/"> */
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:param" element={<Home />} />
      </Routes>
      /*     </BrowserRouter> */
 
  );
}

export default App;
