import SvgUse from "../svg/SvgUse"


const StepFour = () => {
  return (
    <div  className="flex h-[100vh] justify-center gap-4 items-center">
    <p className="headline-2 whitespace-nowrap">Só mais um instante, estamos finalizando...</p>
    <SvgUse id="#icon_smille" classe="!w-24 !h-24 pointer-events-none" />
  </div>
  )
}

export default StepFour
