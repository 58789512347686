export const HttpClientToken = async (url: string, { headers = {}, ...options }) => {
    return await fetch(`${process.env.REACT_APP_URL_EXTERNA}${url}`, {
        headers: {
            ...headers
        },
        mode: 'cors',
        ...options
    }).then(async (response) => {
        const res = await response.json();
        return res;
    }).catch(err => {
        console.log(err);
        throw new Error('Erro na requisição: ' + err.message);
    })
}