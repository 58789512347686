import { useHomeContext } from "../../../../pages/Home/context";
import { GLOBAL } from "../../../../utils/GLOBAL";
import SvgUse from "../../../svg/SvgUse";

const TabTwo = () => {
  const { dataByCpf, nextStep, setHasSecure, onClickSecureOperation, dataTaxa, hasSecure } = useHomeContext();
  
  return (
    <div className="tab-collapse p-24 pb-12 h-full !bg-white" data-tab="tab-3">
      <div className="flex gap-4 items-center">
        <SvgUse id="#icon_cards" classe={`!w-24 !h-24 pointer-events-none`} />
        <p className="headline-2 text-neutral-100 font-medium">Confirme os dados</p>
      </div>

      <div className="flex flex-col gap-16 mt-24 pointer-events-none ">
        <div className="flex gap-16 flex-col">
          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Tipo de produto</p>
            <input
              className="input-text w-full !h-48"
              defaultValue={dataTaxa.nome || ""}
              type="text"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Valor a Receber</p>
            <input
              className="input-text w-full !h-48"
              defaultValue={GLOBAL.FormatCurrencyBR(dataByCpf.valor_liberado)}
              type="text"
            />
          </label>

          <label className="flex-1">
            <p className="caps-2 text-neutral-60">Saldo Bloqueado</p>
            <input
              className="input-text w-full !h-48"
              defaultValue={GLOBAL.FormatCurrencyBR(
                dataByCpf?.valor_emissao || ""
              )}
              type="text"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Taxa A.M</p>
            <input
              className="input-text w-full !h-48"
              value={
                dataByCpf?.taxa_mes ? `${dataByCpf.taxa_mes}%` : "-"
              }
              type="text"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">CET A.M</p>
            <input
              className="input-text w-full !h-48"
              defaultValue={`${
                dataByCpf?.cet_mes ? `${dataByCpf?.cet_mes}%` : "-"
              }`}
              type="text"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Anos de antecipação</p>
            <input
              className="input-text w-full !h-48"
              defaultValue={`${
                dataByCpf?.anos_antecipados
                  ? `${dataByCpf?.anos_antecipados}`
                  : "-"
              }`}
              type="text"
            />
          </label>
        </div>
      </div>
      <div className="flex-1 md:w-full div-check-secure" style={{ display: (dataByCpf?.deseja_seguro ? "inline-flex" : "none") }}>
      {/* // style={{ display: (dataByCpf?.deseja_seguro ? "inline-flex" : "none"), alignItems: "center", placeContent: "center" }}> */}
        <input
          className="input-check input-check-label !h-48"
          checked={hasSecure}
          type="checkbox"
          id="check_has_secure"
          // onClick={() => { setHasSecure(!hasSecure) }}
          onClick={() => { onClickSecureOperation() }}
        />
        <div className="input-check-label text-neutral-60" style={{ marginLeft: "0.3rem" }}>Operação com seguro</div>
      </div>
      <button
        disabled={!hasSecure && dataByCpf?.deseja_seguro}
        onClick={() => nextStep(4)}
        className={`${(!hasSecure && dataByCpf?.deseja_seguro) ? "opacity-60" : ""} h-48 btn flex gap-4 mt-24 flex-1 w-full`}
      >
        <p className="paragraph-1 text-white">Prosseguir</p>
      </button>
    </div>
  );
};

export default TabTwo;
