import { useHomeContext } from "../../../../pages/Home/context";
import { CepMask } from "../../../../utils/constants";
import SvgUse from "../../../svg/SvgUse";
import InputMask from "react-input-mask";

const TabOne = () => {
  const { dataByCpf, dataBank, backStageZero, nextStep } = useHomeContext();
  return (
    <div
      className="tab-collapse h-full  p-24 pb-12 !bg-white "
      data-tab="tab-2"
    >
      <div className="flex gap-4 items-center">
        <SvgUse id="#icon_user" classe={`!w-24 !h-24 pointer-events-none`} />
        <p className="headline-2 text-neutral-100 font-medium" >1. Dados Básicos</p>
      </div>

      <div className="flex flex-col gap-16 mt-24 pointer-events-none">
        <div className="flex gap-16 flex-col">
          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Nome</p>
            <input
              readOnly
              className="input-text w-full !h-48"
              defaultValue={dataByCpf.name || ""}
              type="text"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Documento</p>

            <input
              readOnly
              className="input-text w-full !h-48"
              defaultValue={dataByCpf.document_number || ""}
              type="text"
            />
          </label>

          <label className="flex-1 pointer-events-none">
            <p className="caps-2 text-neutral-60">Data de nascimento</p>
            <div className="pointer-events-none flex-1 text-sm leading-100 text-neutral-70 border border-neutral-30 rounded-md">
              <input
                readOnly
                type="date"
                defaultValue={dataByCpf.birth_date || ""}
                name="data_nascimento"
                id="user-edit-input-date"
                placeholder="Escolha a data"
                className="w-full px-10 h-48 pointer-events-none"
              />
            </div>
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Nome da mãe</p>
            <input
              defaultValue={dataByCpf.mother_name || ""}
              readOnly
              className="input-text w-full !h-48"
              type="text"
            />
          </label>
        </div>
      </div>

      <div className="flex gap-4 items-center mt-24 pointer-events-none">
        <SvgUse id="#icon_layer" classe={`!w-24 !h-24 pointer-events-none`} />
        <p className="headline-2  text-neutral-100 font-medium">2. Endereço</p>
      </div>

      <div className="flex flex-col gap-16 mt-24 pointer-events-none">
        <div className="flex gap-16 flex-col">
          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">CEP</p>
            <InputMask
              readOnly
              className="input-text w-full !h-48"
              defaultValue={dataByCpf.postal_code || ""}
              mask={CepMask}
              placeholder="99999-999"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Rua</p>
            <input
              readOnly
              className="input-text w-full !h-48"
              defaultValue={dataByCpf.street || ""}
              type="text"
            />
          </label>

          <label className="flex-1">
            <p className="caps-2 text-neutral-60">Número</p>
            <input
              readOnly
              defaultValue={dataByCpf.number || ""}
              className="input-text w-full !h-48"
              type="text"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Bairro</p>
            <input
              readOnly
              defaultValue={dataByCpf.neighborhood || ""}
              className="input-text w-full !h-48"
              type="text"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Cidade</p>
            <input
              readOnly
              defaultValue={dataByCpf.city || ""}
              className="input-text w-full !h-48"
              type="text"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Estado</p>
            <input
              readOnly
              defaultValue={dataByCpf.state || ""}
              className="input-text w-full !h-48"
              type="text"
            />
          </label>

          <label className="flex-1 md:w-full">
            <p className="caps-2 text-neutral-60">Complemento</p>
            <input
              readOnly
              defaultValue={dataByCpf.complement || ""}
              className="input-text w-full !h-48"
              type="text"
            />
          </label>
        </div>
      </div>

      <div className="flex gap-4 items-center mt-24">
        <SvgUse id="#icon_wallet" classe={`!w-24 !h-24 pointer-events-none`} />
        <p className="headline-2 text-neutral-100 font-medium">3. Financeiro</p>
      </div>

      <div className="flex flex-col gap-16 mt-24 pointer-events-none">
        <div className="flex gap-16 flex-col">
          <label className="flex-1">
            <p className="caps-2 text-neutral-60">Tipo de conta</p>
            <input
              readOnly
              defaultValue={dataByCpf.conta_pix || ""}
              className="input-text w-full !h-48"
              type="text"
            />
          </label>
          {dataByCpf.conta_pix == "Conta Corrente" ? (
            <div className="flex flex-col gap-16">

              <label className="flex-1 md:w-full">
                          <p className="caps-2 text-neutral-60">Código do Banco</p>
                          <input
                            readOnly
                            defaultValue={dataBank.codigo || ""}
                            className="input-text w-full !h-48"
                            type="text"
                          />
              </label>
              <label className="flex-1 md:w-full">
                <p className="caps-2 text-neutral-60">Banco</p>
                <input
                  readOnly
                  defaultValue={dataBank.nome || ""}
                  className="input-text w-full !h-48"
                  type="text"
                />
              </label>

              <label className="flex-1 md:w-full">
                <p className="caps-2 text-neutral-60">Agência</p>
                <input
                  readOnly
                  defaultValue={dataByCpf.agencia || ""}
                  className="input-text w-full !h-48"
                  type="text"
                />
              </label>

              <label className="flex-1 md:w-full">
                <p className="caps-2 text-neutral-60">Número da Conta</p>
                <input
                  readOnly
                  defaultValue={dataByCpf.conta || ""}
                  className={`input-text w-full !h-48`}
                  type="text"
                />
              </label>

              <label className="flex-1 md:w-full">
                <p className="caps-2 text-neutral-60">Dígito</p>
                <input
                  readOnly
                  defaultValue={dataByCpf.digito_verificador || ""}
                  className="input-text w-full !h-48"
                  type="text"
                />
              </label>
            </div>
          ) : (
            <div
              //  v-else
              className="flex flex-col gap-16"
            >
              <label className="flex-1 md:w-full">
                <p className="caps-2 text-neutral-60">Chave Pix</p>
                <input
                  readOnly
                  defaultValue={dataByCpf.tipo_chave_pix || ""}
                  className="input-text w-full !h-48"
                  type="text"
                />
              </label>

              <label className="flex-1 md:w-full">
                <p className="caps-2 text-neutral-60">Pix</p>
                <input
                  readOnly
                  defaultValue={dataByCpf.pix || ""}
                  className="input-text w-full !h-48"
                  type="text"
                />
              </label>
            </div>
          )}
        </div>
      </div>

      <div className="flex gap-8 items-center">
        <button
          className="hover:!bg-white hover:!brightness-95 h-48 btn flex gap-4 mt-24 flex-1 bg-white !border-solid !border !border-neutral-30"
          onClick={backStageZero}
        >
          <p className="paragraph-1 text-neutral-100">Voltar</p>
        </button>
        <button
          onClick={(e) => nextStep(3)}
          className="h-48 btn flex gap-4 mt-24 flex-1"
        >
          <p className="paragraph-1 text-white">Prosseguir</p>
        </button>
      </div>
    </div>
  );
};

export default TabOne;
