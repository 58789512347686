import { useHomeContext } from "../../../../pages/Home/context";
import { GLOBAL } from "../../../../utils/GLOBAL";

const TabThree = () => {
  const { clicked, openCameraNormal } = useHomeContext();

  // Geolocation is supported

  interface IGeoMessage {
    message: string;
  }

  async function getGeolation(): Promise<IGeoMessage> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        () => {
          resolve({ message: "success" });
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              resolve({
                message: "Permissao para Localização negada pelo usúario",
              });
              break;
            case error.POSITION_UNAVAILABLE:
              resolve({ message: "Informaçao de Localizacáo é invalida" });
              break;
            case error.TIMEOUT:
              resolve({
                message: "O tempo de espera pela Localização  foi encerrado",
              });
              break;
            default:
              resolve({ message: "Erro na Localizaçao" });
              break;
          }
        }
      );
    });
  }

  async function clickOpenCamera() {
    if ("geolocation" in navigator) {
      const status: IGeoMessage = await getGeolation();
      if (status?.message === "success") {
        openCameraNormal();
      } else {
        console.log(status.message);
        GLOBAL.showToastify(status.message);
      }
    } else {
      
      GLOBAL.showToastify('Seu dispositivo nao suporta localização');
    }
  }
  

  return (
    <div className="tab-collapse p-24 pb-12 h-full !bg-white" data-tab="tab-4">
      <div className="flex gap-4 pointer-events-none  justify-center border border-neutral-30 flex-col rounded-md">
        <div className="py-16 px-20 w-full mx-auto">
          <p className="h-[32px] rounded-md whitespace-nowrap flex items-center bg-primary-pure/10 justify-center font-medium headline-3 text-primary-pure">
            Cuidados para tirar a sua foto corretamente
          </p>
        </div>
        <div className="border-b border-b-neutral-30 py-8 px-16 text-left flex">
          <h1 className="caps-2-semibold font-medium text-neutral-100 mr-4 ">1.</h1>
          <p className="text-neutral-60 ">
            Permitir acesso a câmera e localização
          </p>
        </div>
        <div className="border-b border-b-neutral-30 py-8 px-16 text-left flex">
          <h1 className="caps-2-semibold font-medium text-neutral-100 mr-4">2.</h1>
          <p className="text-neutral-60">A foto deve ser tirada de frente</p>
        </div>
        <div className="border-b border-b-neutral-30 py-8 px-16 text-left flex">
          <h1 className="caps-2-semibold font-medium text-neutral-100 mr-4">3.</h1>
          <p className="text-neutral-60">O rosto deve estar centralizado</p>
        </div>
        <div className="border-b border-b-neutral-30 py-8 px-16 text-left flex">
          <h1 className="caps-2-semibold font-medium text-neutral-100 mr-4">4.</h1>
          <p className="text-neutral-60">Os olhos devem estar abertos</p>
        </div>
        <div className="border-b border-b-neutral-30 py-8 px-16 text-left flex">
          <h1 className="caps-2-semibold font-medium text-neutral-100 mr-4">5.</h1>
          <p className="text-neutral-60">Sem óculos, chapéu ou boné</p>
        </div>
        <div className="border-b border-b-neutral-30 py-8 px-16 text-left flex">
          <h1 className="caps-2-semibold font-medium text-neutral-100 mr-4">6.</h1>
          <p className="text-neutral-60">Fisionomia neutra</p>
        </div>
      </div>

      <button
        disabled={clicked}
        className={`${
          clicked ? "opacity-60" : ""
        } h-48 btn flex gap-4 mt-24 w-full`}
        onClick={clickOpenCamera}
      >
        <span className="paragraph-1 text-white">
          {clicked ? "Abrindo camera" : "Iniciar"}
        </span>
        <span className={clicked ? "is-loading" : ""}></span>
      </button>
    </div>
  );
};

export default TabThree;
