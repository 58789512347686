import React from "react";
import { IStepOne } from "./types";
import InputMask from "react-input-mask";
import { useHomeContext } from "../../pages/Home/context";
import { CpfMask } from "../../utils/constants";
import { Dialog } from "primereact/dialog";

const StepZero = (): JSX.Element => {
  const { cpf, dataByCpf, setCpf, loading, getDataByCpf, showAdvertisement, setShowAdvertisement } = useHomeContext();

  const {   corban  } = useHomeContext();
  return (
    <>
      <Dialog draggable={false} header="Número de tentativas excedido"  visible={showAdvertisement} contentClassName="!p-24" className="w-[400px]" onHide={() => setShowAdvertisement(false)}>
        <p className="m-0">
          Você excedeu o número de tentativas em seu reconhecimento facial.
        </p>

        </Dialog>
      <div className="!mt-[80px] px-24 flex justify-center flex-col">
        <img
          className="w-[220px] mx-auto"
          src={corban?.logomarca}
          alt=""
        />
        <div>
          <h1 className="!title-2 mt-40 text-left">Bem Vindo</h1>
          <p className="paragraph-2 text-left">
            Falta pouco para seu FGTS cair em sua conta.
          </p>
        </div>
        <div className="mt-48">
          <label>
            <p className="text-left headline-3">CPF</p>
            <InputMask
              className="input-text w-full !h-48 mb-24"
              value={cpf}
              onChange={(e: any) => {
                setCpf(String(e.target.value).replace(/\_/g, ""));
              }}
              mask={CpfMask}
              inputMode="numeric"
            />
          </label>
          <button
            className={`
              ${
                cpf.length < 14 || cpf.length > 14
                  ? "pointer-events-none opacity-60"
                  : ""
              }
              ${
                loading ? "is-loading opacity-60" : ""
              } h-48 btn flex gap-4 w-full 
            `}
            onClick={() => getDataByCpf(cpf)}
            disabled={cpf.length < 14 || cpf.length > 14}
          >
            <p className="paragraph-3 text-white ">Prosseguir</p>
          </button>
        </div>
      </div>
    </>
  );
};

export default StepZero;
