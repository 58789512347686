import axios from 'axios'
const urlAtual = window.location.href

export const api = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'testes.granapix.com.br',
  // baseURL: urlAtual.includes('biometria-granapix-teste') ? 'https://testes.granapix.com.br' : 'https://granapix.com.br',
  baseURL: urlAtual.includes('dev-biometria') ? 'https://testes.granapix.com.br' : 'https://granapix.com.br',
  headers: {
    Authorization: `Token ${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TOKEN : process.env.REACT_APP_TOKEN_PROD}`
  }
})

