//@ts-nocheck

import { HomeProvider, useHomeContext } from "./context";
import Svg from "../../components/svg/Svg";
import StepZero from "../../components/StepZero";
import StepOne from "../../components/StepOne";
import StepTwo from "../../components/StepTwo";
import StepThree from "../../components/StepThree";
import StepFour from "../../components/StepFour";
import StepFive from "../../components/StepFive";
import { Dialog } from "primereact/dialog";
import { useCallback, useEffect, useState } from "react";
import { HttpClient } from "../../utils/HttpClient";
import { GLOBAL } from "../../utils/GLOBAL";
import { useParams, useRoutes, useSearchParams, useNavigate } from "react-router-dom";
import { log } from "console";

const Loading = (): JSX.Element => {
  return (
    <div className="w-screen h-screen grid place-items-center">Carregando</div>
  );
};

const HomeContent = (): JSX.Element => {
  const {
    stepCurrent,
    showBoxCamera,
    descriptions,
    openOptionSelected,
    optionSelected,
    visible,
    setVisible,
    corban,
    setCorban,
  } = useHomeContext();
  const [requestCorban, setRequestCorban] = useState(false);

  const { param } = useParams();
  const navigate = useNavigate()

  useEffect(async () => {
    await setSlug()
  }, []);

  async function setSlug() {
    let slug = param;
    if (!slug) {
      slug = 'grana-pix'
      navigate("/grana-pix");
    }

    await GLOBAL.getTokens()

    try {      
      const corban = await HttpClient(`/item-biometria/?slug=${slug}`, {
        method: "GET"
      });
      console.log({ corban });
      const _corban = corban?.data;
      console.log({ _corban });
      setCorban(_corban);
      setRequestCorban(true);
    } catch (error) {
      if (error === 'refreshToken') {
        await setSlug();
      }
    }
  }



  function setFavicon(href) {
    let favicon1 = document.querySelector("#favicon1");
    let favicon2 = document.querySelector("#favicon2");
    let favicon3 = document.querySelector("#favicon3");
    let favicon4 = document.querySelector("#favicon4");

    favicon1.href =  href
    favicon2.href =  href
    favicon3.href =  href
    favicon4.href =  href

    // if (!link) {
    //   link = document.createElement("link");
    //   link.rel = "icon";
    //   document.getElementsByTagName("head")[0].appendChild(link);
    // }

    // link.href = href;
  }

  useEffect(() => {
    if (!corban?.id) return;
    const id = corban.id;
    const cor_primaria = corban.simple_cor_primaria || "40, 90, 132";
    const cor_secundaria = corban.simple_cor_secundaria || "133, 200, 114";
    const cor_terciaria = corban.simple_cor_terciaria || "58, 133, 37";
    const logomarca = corban.logomarca || "logo-grana-pix-horizontal.svg";
    const favicon = corban.favicon || "favicon.ico";
    const logo_secundaria = corban.logo_secundaria || logomarca;
    const nome = corban.nome || "Grana Pix"
    

    setFavicon(favicon);

    let root = document.documentElement;
    root.style.setProperty("--primaria", corban?.simple_cor_primaria);
    root.style.setProperty("--secundaria", corban?.simple_cor_secundaria);
    root.style.setProperty("--terciaria", corban?.simple_cor_terciaria);

    document.title = `Biometria ${nome}`

    setCorban({
      id,
      cor_primaria,
      cor_secundaria,
      cor_terciaria,
      logomarca,
      favicon,
      logo_secundaria,
    });

  }, [requestCorban]);

  function currentStep(step: number) {
    const steps: { [key in string]: JSX.Element } = {
      0: <StepZero />,
      1: <StepOne />,
      2: <StepTwo />,
      3: <StepThree />,
      4: <StepFour />,
      5: <StepFive />,
    };

    if (steps[step]) {
      return steps[step];
    } else {
      steps[0];
    }
  }
  if (!requestCorban) return <Loading />;
  else if (requestCorban && !corban?.id)
    return (
      <div className="w-screen h-screen grid place-items-center">
        Corban não encontrado
      </div>
    );
  else
    return (
      <>
        <Dialog
          draggable={false}
          header="Instrução"
          closable={false}
          visible={visible}
          contentClassName="!pb-16"
          className="w-[400px]"
          onHide={() => setVisible(false)}>
          <p className="m-0">{descriptions[optionSelected]}</p>
          <div className="flex justify-center mt-16">
            <button
              className={`h-40 btn flex gap-4 min-w-[200px]`}
              onClick={() => {
                openOptionSelected();
              }}>
              <p className="text-white paragraph-1">Prosseguir</p>
            </button>
          </div>
        </Dialog>
        <main id="informacoesByCpf" className="hidden md:block">
          {currentStep(stepCurrent)}
          {showBoxCamera ? (
            <div className="cameraContainer" id="box-camera"></div>
          ) : null}
        </main>
        <main className="flex flex-col items-center justify-center h-screen gap-16 md:hidden">
          <img className="w-[220px] mx-auto" src={corban.logomarca} alt="" />
          Essa aplicação deve ser acessada por um tablet ou smartphone.
        </main>
      </>
    );
};

const Home = () => {
  return (
    <HomeProvider>
      <Svg />
      <HomeContent />
    </HomeProvider>
  );
};

export default Home;
