import SvgUse from "../svg/SvgUse";

const StepTwo = (): JSX.Element => {
  return (
    <div className="flex h-[100vh] justify-center gap-4 items-center">
      <p className="headline-2 whitespace-nowrap">Por favor aguarde um pouco...</p>
      <SvgUse id="#icon_smille" classe="!w-24 !h-24 pointer-events-none"/>
    </div>
  );
};

export default StepTwo;
