import { useContext, createContext, useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import {
  Bank,
  Corban,
  IHomeContextData,
  IHomeContextProvider,
  OpenCameraState,
  Person,
  Taxa,
} from "./types";
import { GLOBAL } from "../../utils/GLOBAL";
import { HttpClient } from "../../utils/HttpClient";
import {
  UnicoCheckBuilder,
  SelfieCameraTypes,
  UnicoThemeBuilder,
  DocumentCameraTypes,
  CallbackCamera,
  ErrorPictureResponse,
  SuccessPictureResponse,
  SupportPictureResponse,
  DocumentCameraType,
  SelfieCameraType,
} from "unico-webframe";
import { api } from "../../utils/api";

const HomeContext = createContext({} as IHomeContextData);

export const HomeProvider = ({ children }: IHomeContextProvider) => {
  const [cpf, setCpf] = useState<string>("");
  const [stepCurrent, setStepCurrent] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [dataByCpf, setDataByCpf] = useState({} as Person);
  const [dataBank, setDataBank] = useState({} as Bank);
  const [dataTaxa, setDataTaxa] = useState({} as Taxa);
  const [clicked, setClicked] = useState(false);
  const [corban, setCorban] = useState({} as any)
  
  const [visible, setVisible] = useState(false);
  const [optionSelected, setOptionSelected] = useState("");
  
  const [numTentativas, setNumTentativas] = useState(1);
  
  const [maxTentativas, setMaxTentativas] = useState(5);
  
  const [activeTab, setActiveTab] = useState(2);
  
  const [showAdvertisement, setShowAdvertisement] = useState(false);
  
  const [errorMessage, setErrorMessage] = useState("");
  
  const [hasSecure, setHasSecure] = useState(false);

  const openOptionSelected = () => {
    openCameraDocument(optionSelected);
    setVisible(false);
  };

  const descriptions: { [key: string]: string } = {
    CNH: "O CNH precisa ser enviado aberto.",
    RG_FRENTE: "Envie a frente do RG.",
    RG_FRENTE_NOVO: "Envie a frente do RG.",
    RG_VERSO: "Envie o verso do RG",
    RG_VERSO_NOVO: "Envie o verso do RG",
  };

  // USESTATE DA UNICO
  const [showBoxCamera, setShowBoxCamera] = useState(false);
  const checkBiometriaAfterSelfie = async () => {
    try {      
      const dataResponse = await HttpClient(`/naturalperson/${dataByCpf.id}`, {
        method: "GET"
      });
      if (numTentativas >= maxTentativas) {
        backStageZero();
        setNumTentativas(0);
        setActiveTab(2);
        setShowAdvertisement(true);
        return;
      }
  
      if (dataResponse.unico_erro) {
        setShowAdvertisement(true);
        setStepCurrent(1);
        changeTab(4);
        setNumTentativas(numTentativas + 1);
        setErrorMessage(`${dataResponse.unico_erro}!`);
      } else {
        setStepCurrent(3);
      }
    } catch (error) {
      if (error === 'refreshToken') {
        await checkBiometriaAfterSelfie();
      }
    }
  };
  //CALBACKS DA UNICO
  const sdkCallbacks: CallbackCamera = {
    on: {
      success: async (response) => {
        setClicked(false);
        setShowBoxCamera(false);
        setStepCurrent(2);
        resetComponentStates();
        console.log("RESPONSEEEE", response);
        const fingerprint = await GLOBAL.getFingerprintBrowser();
        try {
          const data = await HttpClient(`/naturalperson/${dataByCpf.id}/`, {
            method: "PATCH",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
              unico_base64: `data:image/png;base64,${response.base64}`,
              unico_encrypted: response.encrypted,
              unico_fingerprint: JSON.stringify(fingerprint),
            }),
          });
          if (!data) return;
          setTimeout(() => {
            checkBiometriaAfterSelfie();
          }, 2000);
        } catch (err) {
          console.log(err);
          GLOBAL.showToastify(`Erro ao salvar a selfie, repita o processo.`);
          setStepCurrent(1);
        }
      },
      error: function (error: ErrorPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(1);
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(1);
      },
    },
  };
  const sdkCallbacksCNHFrente: CallbackCamera = {
    on: {
      success: async (response) => {
        setClicked(false);
        setShowBoxCamera(false);
        setStepCurrent(2);
        await api.patch(`/naturalperson/${dataByCpf.id}/`, {
          unico_doc_frente_base64: response.base64,
          unico_doc_frente_encrypted: response.encrypted,
          unico_tipo_doc: "CNH",
        });
        openCameraDocument("CNH_VERSO");
      },
      error: function (error: ErrorPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
      },
    },
  };
  const sdkCallbacksCNHVerso: CallbackCamera = {
    on: {
      success: async (response) => {
        setClicked(false);
        setShowBoxCamera(false);
        setStepCurrent(4);
        await api.patch(`/naturalperson/${dataByCpf.id}/`, {
          unico_doc_verso_base64: response.base64,
          unico_doc_verso_encrypted: response.encrypted,
          unico_tipo_doc: "CNH",
        });
        await postEndHistoryById(dataByCpf.id)
        setStepCurrent(5);
      },
      error: function (error: ErrorPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
      },
    },
  };
  const sdkCallbacksCPF: CallbackCamera = {
    on: {
      success: async (response) => {
        setClicked(false);
        setShowBoxCamera(false);
        setStepCurrent(4);
        await api.patch(`/naturalperson/${dataByCpf.id}/`, {
          unico_doc_frente_base64: response.base64,
          unico_doc_frente_encrypted: response.encrypted,
          unico_tipo_doc: "CPF",
        });
        await postEndHistoryById(dataByCpf.id)
        setStepCurrent(5);
      },
      error: function (error: ErrorPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
      },
    },
  };

  const sdkCallbacksCNH: CallbackCamera = {
    on: {
      success: async (response) => {
        setClicked(false);
        setShowBoxCamera(false);
        setStepCurrent(4);
        console.log("RESPONSE CNH", response);
        try {
          await HttpClient(`/naturalperson/${dataByCpf.id}/`, {
            method: "PATCH",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
              unico_doc_frente_base64: response.base64,
              unico_doc_frente_encrypted: response.encrypted,
              unico_tipo_doc: "CNH ABERTA",
            }),
          });
          await postEndHistoryById(dataByCpf.id)
          setStepCurrent(5);
        } catch (err) {
          console.log(err)
          GLOBAL.showToastify(`Erro ao salvar a CNH, tente novamente.`);
          setStepCurrent(3);
        }
      },
      error: function (error: ErrorPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
    },
  };

  const sdkCallbacksRGFrente: CallbackCamera = {
    on: {
      success: async (response) => {
        setClicked(false);
        setShowBoxCamera(false);
        setStepCurrent(2);
        try {
          await HttpClient(`/naturalperson/${dataByCpf.id}/`, {
            method: "PATCH",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
              unico_doc_frente_base64: response.base64,
              unico_doc_frente_encrypted: response.encrypted,
              unico_tipo_doc: "RG",
            }),
          });
          setOptionSelected("RG_VERSO");
          setVisible(true);
        } catch (err) {
          console.log(err)
          GLOBAL.showToastify(
            `Erro ao salvar a frente do RG, tente novamente.`
          );
          setStepCurrent(3);
        }
        // openCameraDocument("RG_VERSO");
      },
      error: function (error: ErrorPictureResponse) {
        console.log("ERROR:", error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
      support: function (error: SupportPictureResponse) {
        console.log("Support", error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
    },
  };
  const sdkCallbacksRGVERSO: CallbackCamera = {
    on: {
      success: async (response) => {
        setClicked(false);
        setShowBoxCamera(false);
        setStepCurrent(4);
        try {
          await HttpClient(`/naturalperson/${dataByCpf.id}/`, {
            method: "PATCH",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
              unico_doc_verso_base64: response.base64,
              unico_doc_verso_encrypted: response.encrypted,
              unico_tipo_doc: "RG",
            }),
          });
          await postEndHistoryById(dataByCpf.id)
          setStepCurrent(5);
        } catch (err) {
          console.log(err)
          GLOBAL.showToastify(`Erro ao salvar o verso do RG, tente novamente.`);
          setStepCurrent(3);
        }
      },
      error: function (error: ErrorPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
    },
  };
  const sdkCallbacksRGFrenteNovo: CallbackCamera = {
    on: {
      success: async (response) => {
        setClicked(false);
        setShowBoxCamera(false);
        setStepCurrent(2);
        try {
          await HttpClient(`/naturalperson/${dataByCpf.id}/`, {
            method: "PATCH",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
              unico_doc_frente_base64: response.base64,
              unico_doc_frente_encrypted: response.encrypted,
              unico_tipo_doc: "RG NOVO",
            }),
          });
          setOptionSelected("RG_VERSO_NOVO");
          setVisible(true);
        } catch (err) {
          console.log(err)
          GLOBAL.showToastify(
            `Erro ao salvar a frente do RG, tente novamente.`
          );
          setStepCurrent(3);
        }
      },
      error: function (error: ErrorPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
    },
  };
  const sdkCallbacksRGVersoNovo: CallbackCamera = {
    on: {
      success: async (response) => {
        setClicked(false);
        setShowBoxCamera(false);
        setStepCurrent(4);
        try {
          await HttpClient(`/naturalperson/${dataByCpf.id}/`, {
            method: "PATCH",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
              unico_doc_verso_base64: response.base64,
              unico_doc_verso_encrypted: response.encrypted,
              unico_tipo_doc: "RG NOVO",
            }),
          });
          await postEndHistoryById(dataByCpf.id)
          setStepCurrent(5);
        } catch (err) {
          console.log(err)
          GLOBAL.showToastify(`Erro ao salvar o verso do RG, tente novamente.`);
          setStepCurrent(3);
        }
      },
      error: function (error: ErrorPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
        setClicked(false);
        resetComponentStates();
        setStepCurrent(3);
      },
    },
  };

  const getDataByCpf = async (cpf: string) => {
    setLoading(true);
    try {      
      const { results } = await HttpClient(
        `/naturalperson/?document_number=${cpf}&status=waiting_signature`,
        {
          method: "GET",
        }
      );

      
      if (results?.length && results[0]?.id) {
        getDataBankById(results[0]?.banco);
        getDataTaxaById(results[0]?.taxa_juros);
        postStartHistoryById(results[0]?.id);
        //results[0]?.status == 'waiting_signature'
        setDataByCpf(results[0]);
        setStepCurrent(1);
      } else {
        GLOBAL.showToastify(
          "Nenhum usuário foi encontrado, verifique o CPF e tente novamente."
        );
      }
      setLoading(false);
    } catch (error: any) {
      if (error?.message === 'refreshToken') {
        await getDataByCpf(cpf);
      }
    }
  };

  const getDataBankById = async (id: string) => {
    try {      
      const infoBank = await HttpClient(`/banco/${id}`, {
        method: "GET",
      });
  
      if (infoBank?.id) {
        setDataBank(infoBank);
      }
    } catch (error: any) {
      if (error?.message === 'refreshToken') {
        await getDataBankById(id);
      }
    }
  };

  const getDataTaxaById = async (id: string) => {
    try {      
      const infoTaxa = await HttpClient(`/taxa_juros/${id}/`, {
        method: "GET",
      });
  
      if (infoTaxa?.id) {
        setDataTaxa(infoTaxa);
      }
    } catch (error: any) {
      if (error?.message === 'refreshToken') {
        await getDataTaxaById(id);
      }
    }
  };

  const postStartHistoryById = async (id: string) => {
    try {      
      const response = await HttpClient(`/naturalperson/${id}/historico_abriu_biometria/`, {
        method: "POST",
      });

      console.log('POST COMEÇO HISTÓRICO --> ', response)
  
    } catch (error: any) {
      if (error?.message === 'refreshToken') {
        await postStartHistoryById(id);
      }
    }
  };

  const postEndHistoryById = async (id: any) => {
    try {      
      const response = await HttpClient(`/naturalperson/${id}/historico_encerrou_biometria/`, {
        method: "POST",
      });

      console.log('POST FIM HISTÓRICO --> ', response)
  
    } catch (error: any) {
      if (error?.message === 'refreshToken') {
        await postEndHistoryById(id);
      }
    }
  };

  const postCheckSecureHistoryById = async (id: any) => {
    try {      
      const response = await HttpClient(`/naturalperson/${id}/historico_confirmou_seguro/`, {
        method: "POST",
      });

      console.log('POST HABILITAR OPERAÇÃO COM SEGURO --> ', response)
  
    } catch (error: any) {
      if (error?.message === 'refreshToken') {
        await postCheckSecureHistoryById(id);
      }
    }
  };
  const postUncheckSecureHistoryById = async (id: any) => {
    try {      
      const response = await HttpClient(`/naturalperson/${id}/historico_desmarcou_seguro/`, {
        method: "POST",
      });

      console.log('POST DESABILITAR OPERAÇÃO COM SEGURO --> ', response)
  
    } catch (error: any) {
      if (error?.message === 'refreshToken') {
        await postUncheckSecureHistoryById(id);
      }
    }
  };

  function changeTab(numberTab: number) {
    console.log("numberTab", numberTab);
    setActiveTab(numberTab);
  }

  function backStageZero() {
    setDataByCpf({} as Person);
    setCpf("");
    setStepCurrent(0);
  }

  function nextStep(step: number) {
    setActiveTab(step);
  }

  async function onClickSecureOperation() {
    setHasSecure(!hasSecure)
    if (!hasSecure) {
      await postCheckSecureHistoryById(dataByCpf.id)
    } else {
      await postUncheckSecureHistoryById(dataByCpf.id)
    }
  }

  function resetComponentStates() {
    setShowBoxCamera(false);
    setLoading(false);
  }

  const urlPathModels = `${window.location.protocol}//${window.location.host}/models`;

  const unicoTheme = new UnicoThemeBuilder()
    .setColorSilhouetteSuccess("#69c62f")
    .setColorSilhouetteError("#D50000")
    .setColorSilhouetteNeutral("#fcfcfc")
    .setBackgroundColor("#e9e9e9")
    .setColorText("#1362ca")
    .setBackgroundColorComponents("#325ad4")
    .setColorTextComponents("#dff1f5")
    .setBackgroundColorButtons("#406ee2")
    .setColorTextButtons("#dff1f5")
    .setBackgroundColorBoxMessage("#fff")
    .setColorTextBoxMessage("#366fd8")
    .setHtmlPopupLoading(
      `<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Abrindo a câmera...</div>`
    )
    .build();

  const unicoCamera = new UnicoCheckBuilder()
    .setTheme(unicoTheme)
    .setModelsPath(urlPathModels)
    .setResourceDirectory("/resources")
    .build();

  const prepareSelfieCamera = async (
    jsonPath: string,
    cameraType: SelfieCameraType
  ) => {
    const { open } = await unicoCamera.prepareSelfieCamera(
      jsonPath,
      cameraType
    );
    setShowBoxCamera(true);
    open(sdkCallbacks);
  };

  const prepareDocumentCamera = async (
    jsonPath: string,
    cameraType: DocumentCameraType,
    type: string
  ) => {
    const { open } = await unicoCamera.prepareDocumentCamera(
      jsonPath,
      cameraType
    );
    setShowBoxCamera(true);
    open(callbacksDocumentByType[type]);
  };

  const callbacksDocumentByType: { [key in string]: any } = {
    CNH_FRENTE: sdkCallbacksCNHFrente,
    CNH_VERSO: sdkCallbacksCNHVerso,
    CPF: sdkCallbacksCPF,
    CNH: sdkCallbacksCNH,
    RG_FRENTE: sdkCallbacksRGFrente,
    RG_VERSO: sdkCallbacksRGVERSO,
    RG_FRENTE_NOVO: sdkCallbacksRGFrenteNovo,
    RG_VERSO_NOVO: sdkCallbacksRGVersoNovo,
  };

  // const urlAtual = window.location.href;
  // services-sem-facetec_teste_minha_biometria
  const isHomolog = process.env?.REACT_APP_HOMOLOG === "True"
  const isMinhaBiometria = window.location.origin.includes('minhabiometria')

  const getArquivo = () => {
    if (isMinhaBiometria) return '/services-sem-facetec_minha_biometria.json'
    else if (isHomolog) return '/services-sem-facetec_teste.json'
    else return '/services-sem-facetec.json'
  }

  const arquivo = getArquivo();
  const openCameraNormal = () => {
    setClicked(true);
    prepareSelfieCamera(
      arquivo, SelfieCameraTypes.NORMAL);
  };

  const openCameraDocument = (type: string) => {
    setClicked(true);
    setShowBoxCamera(true);
    prepareDocumentCamera(
      arquivo,
      DocumentCameraTypes[type],
      type
    );
  };

  return (
    <HomeContext.Provider
      value={{
        cpf,
        setCpf,
        stepCurrent,
        setStepCurrent,
        loading,
        dataByCpf,
        getDataByCpf,
        changeTab,
        activeTab,
        dataTaxa,
        dataBank,
        backStageZero,
        onClickSecureOperation,
        nextStep,
        setHasSecure,
        clicked,
        hasSecure,
        openCameraNormal,
        openCameraDocument,
        showBoxCamera,
        descriptions,
        setOptionSelected,
        openOptionSelected,
        optionSelected,
        visible,
        setVisible,
        numTentativas,
        maxTentativas,
        showAdvertisement,
        setShowAdvertisement,
        errorMessage,
        corban, 
        setCorban
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export const useHomeContext = (): IHomeContextData => {
  const context = useContext(HomeContext);
  if (!context) {
    throw new Error("UseHomeProvider must be used in HomeProvider");
  }
  return context;
};
