import React, {useState} from "react";
import { useHomeContext } from "../../pages/Home/context";
import SvgUse from "../svg/SvgUse";


const StepThree = () => {
  const { openCameraDocument, clicked, openOptionSelected, descriptions, optionSelected, visible, setVisible, setOptionSelected } = useHomeContext();

  return (
    <div className="flex flex-col h-[100vh] justify-center items-center gap-16">
      <div className="flex justify-center gap-4 items-center">
        <p className="headline-2 whitespace-nowrap">Escolha seu documento</p>
        <SvgUse
          id="#icon_documents_step"
          classe="!w-24 !h-24 pointer-events-none"
        />
      </div>
      <button
        disabled={clicked}
        className={`h-48 btn flex gap-4 mt-24 w-[75vw] ${
          clicked ? "opacity-60" : ""
        }`}
        onClick={() => {
          setOptionSelected('CNH');
          setVisible(true);
        }}
      >
        <p className="paragraph-1 text-white">
          {clicked ? "Abrindo camera" : "CNH"}
        </p>
        <span className={clicked ? "is-loading" : ""}></span>
      </button>

      <button
        disabled={clicked}
        className={`h-48 btn flex gap-4 mt-16 w-[75vw] ${
          clicked ? "opacity-60" : ""
        }`}
        onClick={() => {
          setOptionSelected('RG_FRENTE');
          setVisible(true);
        }}
      >
        <p className="paragraph-1 text-white">
          {clicked ? "Abrindo camera" : "RG"}
        </p>
        <span className={clicked ? "is-loading" : ""}></span>
      </button>

      <button
        disabled={clicked}
        className={`h-48 btn flex gap-4 mt-16 w-[75vw] ${
          clicked ? "opacity-60" : ""
        }`}
        onClick={() => {
          setOptionSelected('RG_FRENTE_NOVO');
          setVisible(true);
        }}
      >
        <p className="paragraph-1 text-white">
          {clicked ? "Abrindo camera" : "RG NOVO"}
        </p>
        <span className={clicked ? "is-loading" : ""}></span>
      </button>
    </div>
  );
};

export default StepThree;
