interface ISvgUse{
	id?: string;
	classe?: string;
	[props:string]: any;
}

const SvgUse = ({ id, classe, ...props}: ISvgUse) => {
	return (
		<>
			<svg  className={classe} {...props}>
				<use xlinkHref={id}></use>
			</svg>
		</>
	);
}

export default SvgUse;
