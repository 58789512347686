import SvgUse from "../svg/SvgUse";

const StepFive = () => {
  return (
    <div className="flex h-[100vh] justify-center gap-4 items-center">
      <p className="headline-1">Obrigado, processo concluído.</p>
      <SvgUse id="#icon_check" classe="!w-56 !h-56 pointer-events-none" />
    </div>
  );
};

export default StepFive;
